import React, { useState, useEffect, useCallback } from "react"
import { useDialogState } from "reakit/Dialog"
import { gql, useQuery } from "@apollo/client"
import getConfig from "next/config"
import Router from "next/router"
import { useCurrentUser } from "app/lib/CurrentUser"
import EventFeedbackBody from "./EventFeedbackBody"
import EventQuestions from "./EventQuestions"
import { useOrganization } from "app/common/Organization"

const { publicRuntimeConfig } = getConfig()
const isStaging = publicRuntimeConfig.RUNNING_ENV === "staging"

const FeedbackContext = React.createContext()

const unansweredEvents = gql`
  query GetUnansweredEvent {
    unansweredEvents(first: 1) {
      edges {
        node {
          id
          title
          startedAt
          endedAt
          feedbackRequestEnabled
          questions {
            edges {
              node {
                id
                questionType
                title
              }
            }
          }
        }
      }
    }
  }
`

const twoMinutes = 2 * 60000
const tenMinutes = 10 * 60000
const interval = isStaging ? twoMinutes : tenMinutes
const delay = isStaging ? 60000 : twoMinutes

export default function FeedbackProvider({ children }) {
  const currentUser = useCurrentUser()
  const organization = useOrganization()
  const dialog = useDialogState({ modal: false })
  const [feedback, setFeedback] = useState(null)
  const showFeedbackRequest = currentUser.hasPermission("event:register")
  const { data, startPolling, stopPolling } = useQuery(unansweredEvents, {
    skip: !showFeedbackRequest || !organization?.isChildless,
  })

  const closeFeedbackDialog = useCallback(() => {
    dialog.hide()
    setFeedback(null)
  }, [dialog])

  useEffect(() => {
    if (showFeedbackRequest && organization?.isChildless) {
      startPolling?.(interval)
    } else {
      stopPolling?.()
    }
    return () => stopPolling()
  }, [
    showFeedbackRequest,
    startPolling,
    stopPolling,
    organization?.isChildless,
  ])

  useEffect(() => {
    let delayTimer

    if (!feedback && data?.unansweredEvents?.edges?.length) {
      const [first] = data.unansweredEvents.edges.slice(0, 1)
      const { node: event } = first

      if (event) {
        delayTimer = setTimeout(() => {
          setFeedback({
            id: event.id,
            title: "You were registered for this event. Did you attend?",
            bodyElement: (
              <EventFeedbackBody
                event={event}
                onCancel={closeFeedbackDialog}
                onConfirm={() => {
                  if (event.feedbackRequestEnabled) {
                    Router.push(
                      `/${organization.slug}/events/${event.id}/questions`
                    )
                  } else {
                    setFeedback(null)
                  }
                  dialog.hide()
                }}
              />
            ),
            renderQuestions: (props) => (
              <EventQuestions event={event} {...props} />
            ),
          })
          dialog.show()
        }, delay)
      }
    }
    return () => clearTimeout(delayTimer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeFeedbackDialog,
    data?.unansweredEvents?.edges?.length,
    dialog,
    feedback,
  ])

  return (
    <FeedbackContext.Provider
      value={{
        dialogState: dialog,
        close: closeFeedbackDialog,
        feedback,
        clearFeedback: () => setFeedback(null),
      }}
    >
      {children}
    </FeedbackContext.Provider>
  )
}

export const useFeedbackState = () => React.useContext(FeedbackContext)

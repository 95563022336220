import React from "react"
import { Checkbox } from "app/wellzesta-ui"
import clsx from "clsx"

const RATE_OPTIONS = [1, 2, 3, 4, 5]

export default function RateInput({ name, value = 0, onChange }) {
  return (
    <div className="flex items-center space-x-2 md:space-x-4">
      {RATE_OPTIONS.map((it) => {
        let checked = it <= value
        let checkName = `${name}–${it}`

        return (
          <label
            key={checkName}
            htmlFor={checkName}
            className="group flex items-center justify-center min-h-action w-12 rounded-md 
            hover:text-tertiary-lighten text-white cursor-pointer"
          >
            <Checkbox
              name={checkName}
              size="sm"
              id={checkName}
              checked={checked}
              onChange={() => onChange(it)}
              aria-label={`rate this with ${it}`}
              className="hidden"
            />
            <StarIcon
              className={clsx(
                checked
                  ? "text-tertiary group-hover:text-tertiary-lighten"
                  : "stroke-black group-hover:stroke-current stroke-2 group-hover:stroke-0"
              )}
            />
          </label>
        )
      })}
    </div>
  )
}

function StarIcon({ className }) {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 18 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.09144 13.9496L8.6261 13.705L8.16076 13.9496L5.62988 15.2802L6.09522 16.1653L5.62987 15.2802C4.73025 15.7531 3.67881 14.9892 3.85062 13.9875L4.33397 11.1693L4.42285 10.6511L4.04638 10.2842L1.99885 8.28832C1.27104 7.57888 1.67265 6.34283 2.67846 6.19668L5.50808 5.78551L6.02835 5.70992L6.26102 5.23847L7.52646 2.67441C7.97627 1.76299 9.27593 1.76299 9.72574 2.67441L10.9912 5.23847L11.2239 5.70992L11.7441 5.78551L14.5737 6.19668C15.5795 6.34283 15.9812 7.57888 15.2533 8.28832L13.2058 10.2842L12.8294 10.6511L12.9182 11.1693L13.4016 13.9875C13.5734 14.9892 12.5219 15.7531 11.6223 15.2802L9.09144 13.9496Z" />
    </svg>
  )
}

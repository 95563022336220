import React from "react"
import { gql, useMutation } from "@apollo/client"
import { Text, Button } from "app/wellzesta-ui"
import DatePrinter from "app/common/DatePrinter"

const confirmEventAttendanceMutation = gql`
  mutation ConfirmEventAttendance($eventId: ID!, $attended: Boolean!) {
    confirmEventAttendance(eventId: $eventId, attended: $attended) {
      id
    }
  }
`

export default function EventFeedbackBody({ event, onCancel, onConfirm }) {
  const [confirmEventAttendance, { loading, error }] = useMutation(
    confirmEventAttendanceMutation
  )

  return (
    <>
      <Text size="md" className="block font-semibold">
        {event.title}
      </Text>
      <Text size="md" className="block font-semibold">
        <DatePrinter value={event.endedAt} format="MMM DD, h:mm A" />
      </Text>
      <div className="mt-4 flex justify-end space-x-3">
        {error && (
          <Text role="alert" size="sm" className="block my-2 text-red-200">
            Something happened! Please, try again.
          </Text>
        )}
        <Button
          theme="inverse"
          size="md"
          disabled={loading}
          onClick={() =>
            confirmEventAttendance({
              variables: { eventId: event.id, attended: false },
            }).then(onCancel)
          }
        >
          No
        </Button>
        <Button
          theme="tertiary"
          size="md"
          disabled={loading}
          onClick={() =>
            confirmEventAttendance({
              variables: { eventId: event.id, attended: true },
            }).then(onConfirm)
          }
        >
          Yes
        </Button>
      </div>
    </>
  )
}
